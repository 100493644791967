import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCloseAlt: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M10.293 11.707a1 1 0 001.414-1.414L9.414 8l2.293-2.293a1 1 0 00-1.414-1.414L8 6.586 5.707 4.293a1 1 0 00-1.414 1.414L6.586 8l-2.293 2.293a1 1 0 101.414 1.414L8 9.414z"
        fill="#6f727f"
        fillRule="evenodd"
      />
    </Icon>
  )
}
export default SvgCloseAlt
