import { createRef, FC, ReactNode, RefObject } from 'react'

import { FloatingLabel } from '@/ui/atoms/floating-label'
import { TextInput } from '@/ui/atoms/text-input'
import { createSequentialId } from '@/ui/helpers/createSequentialId'
import { getAriaDescribedByValue } from '@/ui/helpers/getAriaDescribedByValue'
import { FormControl, FormControlInput } from '@/ui/molecules/form-control'
import { MaxLengthIndicator } from '@/ui/molecules/max-length-indicator'
import { InputWrapper } from '@/ui/molecules/text-field/styled'
import { CommonInputFieldProps } from '@/ui/typings/commonTextInput'

export type TextFieldProps = {
  /**
   * Element placed after the input
   */
  endAdornment?: ReactNode
  /**
   * Element placed before the input
   */
  startAdornment?: ReactNode
} & CommonInputFieldProps

/**
 * @description Text field component
 */
export const TextField: FC<TextFieldProps> = ({
  bottom = '2rem',
  endAdornment,
  error,
  helperText,
  id,
  label,
  maxLength,
  startAdornment,
  type = 'text',
  value,
  ...rest
}) => {
  const inputRef: RefObject<HTMLInputElement> = createRef()

  const sequentialErrorId =
    error && createSequentialId(`${id}-text-field-error-`)()

  const ariaDescribedByValue = getAriaDescribedByValue(
    sequentialErrorId,
    helperText,
    id
  )

  const textInput = () => (
    <TextInput
      aria-describedby={ariaDescribedByValue}
      errorId={sequentialErrorId}
      id={id}
      ref={inputRef}
      type={type}
      value={value}
      {...rest}
    />
  )

  return (
    <FormControl
      bottom={bottom}
      error={error}
      errorId={sequentialErrorId}
      helperText={helperText}
      id={id}
      isFullWidth
    >
      {startAdornment || endAdornment ? null : (
        <FloatingLabel hasError={!!error} htmlFor={id} inputRef={inputRef}>
          {label}
        </FloatingLabel>
      )}

      <MaxLengthIndicator id={id} maxLength={maxLength} value={value}>
        {startAdornment || endAdornment ? (
          <FormControlInput
            endAdornment={endAdornment}
            error={error}
            inputRef={inputRef}
            startAdornment={startAdornment}
          >
            <InputWrapper>
              <FloatingLabel htmlFor={id} inputRef={inputRef}>
                {label}
              </FloatingLabel>
              {textInput()}
            </InputWrapper>
          </FormControlInput>
        ) : (
          <>{textInput()}</>
        )}
      </MaxLengthIndicator>
    </FormControl>
  )
}
