import { type PersonalAccount } from '@kijiji/generated/graphql-types'

import { AC_URL, ICO_UTM_PARAMS } from '@/constants/ico'
import { ROUTE_LOCALE } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { getAutoCanadaWebLabel } from '@/lib/ga/events/autoCanada'
import { getUserDevice } from '@/utils/getUserDevice'

export type ResponsiveImage = {
  mobile: string
  medium: string
  large: string
  xLarge: string
}

export type IcoDataProps = {
  ctaId?: string
  odometer?: string
  province?: string
  vin?: string
}

export type UtmParameters = {
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  utmContent?: string
}

export const ICO_IMAGE: ResponsiveImage = {
  mobile: '/next-assets/images/autocanada/img-ico-mweb.webp',
  medium: '/next-assets/images/autocanada/img-ico-744.webp',
  large: '/next-assets/images/autocanada/img-ico-1024.webp',
  xLarge: '/next-assets/images/autocanada/img-ico-1440.webp',
}
export const VALUE_ESTIMATE_IMAGE: ResponsiveImage = {
  mobile: '/next-assets/images/autocanada/img-value-estimate-mweb.webp',
  medium: '/next-assets/images/autocanada/img-value-estimate-744.webp',
  large: '/next-assets/images/autocanada/img-value-estimate-1024.webp',
  xLarge: '/next-assets/images/autocanada/img-value-estimate-1440.webp',
}
export const VALUE_ESTIMATE_IMAGE_FR: ResponsiveImage = {
  mobile: '/next-assets/images/autocanada/img-value-estimate-mweb-fr.webp',
  medium: '/next-assets/images/autocanada/img-value-estimate-744-fr.webp',
  large: '/next-assets/images/autocanada/img-value-estimate-1024-fr.webp',
  xLarge: '/next-assets/images/autocanada/img-value-estimate-1440-fr.webp',
}

export const getAutoCanHeaderImageSource = (
  size: keyof ResponsiveImage,
  isICO: boolean,
  isFrench: boolean
) => {
  if (isICO) {
    return ICO_IMAGE[size]
  }
  if (isFrench) {
    return VALUE_ESTIMATE_IMAGE_FR[size]
  }
  return VALUE_ESTIMATE_IMAGE[size]
}

export const openIcoModal = ({
  routeLocale,
  icoDataProps,
  profileData,
  utmParams,
  onError,
  onClose,
}: {
  routeLocale: string
  icoDataProps: IcoDataProps
  profileData?: PersonalAccount
  utmParams?: UtmParameters
  onError?: () => void
  onClose?: () => void
}) => {
  // Initialize the value estimate modal from AutoCanada's SDK.
  global.window.acIco?.initModal('ico', 'kijiji', {
    data: {
      ...icoDataProps,
      ...mapUserData(profileData),
    },
    locale: routeLocale,
    utmParams: {
      ...utmParams,
      utmMedium: getUserDevice().isPhoneOrTablet
        ? ICO_UTM_PARAMS.UTM_MEDIUM_MWEB
        : ICO_UTM_PARAMS.UTM_MEDIUM_WEB,
    },
    onSubmittedForOffer: () => {
      trackEvent({ action: GA_EVENT.IcoComplete, label: getAutoCanadaWebLabel() })
    },
    onClose,
    onError,
  })
}

export const mapUserData = (profileData?: PersonalAccount) => {
  return {
    email: profileData?.email || '',
    userId: profileData?.id || '',
    phone: profileData?.phoneNumber || '',
    firstName: profileData?.name?.split(' ')[0] || '',
    lastName: profileData?.name?.split(' ').pop() || '',
  }
}

export const getIcoScriptURL = () => {
  return `${process.env.NEXT_PUBLIC_ICO_SDK_URL}${AC_URL.ICO_SCRIPT_URL}`
}

export const getLocalizedBillOfSalePath = ({
  routeLocale,
  userId,
  ctaId,
  utmParams = { utmSource: ICO_UTM_PARAMS.UTM_SOURCE },
  isMobile = false,
}: {
  routeLocale: string
  userId?: string
  ctaId?: string
  utmParams?: UtmParameters
  isMobile?: boolean
}) => {
  const path = routeLocale === ROUTE_LOCALE.fr ? AC_URL.BOS_FR : AC_URL.BOS_EN

  const userIdString = userId ? `userId=${encodeURIComponent(userId.toString())}&` : ''
  const ctaIdString = ctaId ? `ctaId=${encodeURIComponent(ctaId)}&` : ''
  const utmMedium = `utm_medium=${isMobile ? 'mweb' : 'web'}`

  // let url = `${path}?${userIdString}${ctaIdString}`
  let url = `${path}?${userIdString}${ctaIdString}utm_source=${utmParams.utmSource}&${utmMedium}&`
  if (utmParams.utmContent && utmParams.utmCampaign) {
    url += `utm_campaign=${utmParams.utmCampaign}&utm_content=${utmParams.utmContent}`
  }
  return url.endsWith('&') ? url.slice(0, -1) : url
}

export const openBillOfSale = (
  routeLocale: string,
  userId?: string,
  ctaId?: string,
  utmParams?: UtmParameters
) => {
  const generatedPath = getLocalizedBillOfSalePath({
    routeLocale,
    userId,
    ctaId,
    isMobile: getUserDevice().isPhoneOrTablet,
    utmParams,
  })

  const billOfSaleUrl = `${process.env.NEXT_PUBLIC_ICO_SDK_URL}${generatedPath}`

  window.open(billOfSaleUrl)
}
