export const AC_URL = {
  ICO_SCRIPT_ID: 'autocanada-ico-js',
  ICO_SCRIPT_URL: '/scripts/ac-ico-sdk-qa-v1.min.js',
  BOS_EN: '/en/billofsale/kijiji',
  BOS_FR: '/fr/billofsale/kijiji',
}

export const AUTOCAN_CTA_IDS = {
  BOS_LANDING_PAGE_CVC: 'web-bos-landing-page-car-value-calculator',
  BOS_LANDING_PAGE_SMC: 'web-bos-landing-page-sell-my-car',
  ICO_LANDING_PAGE_CVC: 'web-ico-landing-page-car-value-calculator',
  ICO_LANDING_PAGE_SMC: 'web-ico-landing-page-sell-my-car',
  VALUE_ESTIMATE_SRP: 'web-value-estimate-srp',
  VALUE_ESTIMATE_LANDING_PAGE_CVC: 'web-value-estimate-landing-page-car-value-calculator',
  VALUE_ESTIMATE_VIP: 'web-value-estimate-vip',
  WARRANTY_VIP: 'web-warranty-vip',
}

export const ICO_UTM_PARAMS = {
  UTM_SOURCE: 'kijiji',
  UTM_MEDIUM_WEB: 'web',
  UTM_MEDIUM_MWEB: 'mweb',
  UTM_MEDIUM_APP: 'app',
  UTM_CAMPAIGN_ICO: 'ico',
  UTM_CAMPAIGN_ICO_BOS: 'ico-billofsale',
  UTM_CONTENT_ESTIMATE_LANDING: 'estimate-landing',
  UTM_CONTENT_ESTIMATE_VIP: 'estimate-vip',
  UTM_CONTENT_ICO_ESTIMATE_LANDING: 'ico-estimate-landing',
  UTM_CONTENT_ICO_LANDING_CTA1: 'ico-landing-cta1',
  UTM_CONTENT_ICO_LANDING_CTA2: 'ico-landing-cta2',
  UTM_CONTENT_ICO_PAF: 'ico-paf',
  UTM_CONTENT_ICO_PAF_EDIT_ADS: 'ico-paf-edit-ads',
  UTM_CONTENT_ICO_MY_ADS: 'ico-myads',
  UTM_CONTENT_ICO_LANDING: 'ico-landing',
  UTM_CONTENT_ESTIMATE_SRP: 'estimate-srp',
}
