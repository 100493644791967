const ATTRIBUTES_AUTOS = {
  BOAT_MAKE: 'boatsmake',
  CAB_TYPE: 'cabtype',
  CARFAX: 'carprooflink',
  CAR_BODY_TYPE: 'carbodytype',
  CAR_COLOR: 'carcolor',
  CAR_ENGINE_POWER: 'chtruckenginepower',
  CAR_FUEL_TYPE: 'carfueltype',
  CAR_MAKE: 'carmake',
  CAR_MILEAGE: 'carmileageinkms',
  CAR_MODEL: 'carmodel',
  CAR_TRANSMISSION: 'cartransmission',
  CAR_TRIM: 'cartrim',
  CAR_YEAR: 'caryear',
  CONDITION: 'condition',
  CPO: 'cpoprogram',
  CRUISE_CONTROL: 'cruisecontrol',
  CYLINDERS: 'cylinders',
  DRIVE_TRAIN: 'drivetrain',
  ELECTRIC_RANGE: 'electricrange',
  HEAVY_EQUIPMENT_TYPE: 'heavyequiptype',
  MOTORCYCLE_DRIVE_TRAIN: 'motorcyclesdrivetrain',
  MOTORCYCLE_FUEL_TYPE: 'motorcyclesfueltype',
  MOTORCYCLE_MAKE: 'motorcyclesmake',
  MOTORCYCLE_MODEL: 'motorcyclesmodel',
  MOTORCYCLE_TRANSMISSION: 'motorcyclestransmission',
  MOTORCYCLE_TRIM: 'motorcyclestrim',
  NUM_DOORS: 'noofdoors',
  NUM_SEATS: 'noofseats',
  STOCK: 'stock',
  TIME_TO_CHARGE: 'timetocharge',
  VEHICLE_TYPE: 'vehicletype',
  VIN: 'vin',
} as const

const ATTRIBUTES_RE = {
  ACCESSIBLE_WASHROOMS: 'accessiblewashroomsinsuite',
  AGREEMENT_TYPE: 'agreementtype',
  AIR_CONDITIONING: 'airconditioning',
  AREA_IN_FEET: 'areainfeet',
  AUDIO_PROMPTS: 'audioprompts',
  BALCONY: 'balcony',
  BARRIER_FREE: 'barrierfreeentrancesandramps',
  BATHROOMS: 'numberbathrooms',
  BEDROOMS: 'numberbedrooms',
  BRAILLE_LABELS: 'braillelabels',
  CABLE_TV: 'cabletv',
  DATE_AVAILABLE: 'dateavailable',
  DISHWASHER: 'dishwasher',
  FOR_RENT_BY_HOUSING: 'forrentbyhousing',
  FRIDGE: 'fridgefreezer',
  FURNISHED: 'furnished',
  HEAT: 'heat',
  HYDRO: 'hydro',
  INTERNET: 'internet',
  LAUNDRY_BUILDING: 'laundryinbuilding',
  LAUNDRY_UNIT: 'laundryinunit',
  PARKING: 'numberparkingspots',
  PET_FRIENDLY: 'petsallowed',
  RENTALS_VIRTUAL_OPTIONS: 'rentalsvirtualoptions',
  SMOKING_PERMITTED: 'smokingpermitted',
  TERM_AGREEMENT: 'termagreement',
  TRAILERS_HITCH: 'trailerhitch',
  UNIT_TYPE: 'unittype',
  VISUAL_AIDS: 'visualaids',
  WATER: 'water',
  WHEELCHAIR_ACCESSIBLE: 'wheelchairaccessible',
  YARD: 'yard',
} as const

const ATTRIBUTES_PETS = {
  BIRTHDAY: 'birthdate',
  BIRTH_DATE: 'birthdate',
  BREED: 'breed',
  GENDER: 'sex',
} as const

const ATTRIBUTES_BUY_SELL = {
  BRAND_DESKTOP: 'desktopbrand',
  BRAND_LAPTOP: 'laptopbrand',
  BRAND_PHONE: 'phonebrand',
  BRAND_TABLET: 'tabletbrand',
  CASHLESS_PAYMENT: 'cashless',
  CASH_ACCEPTED: 'cashaccepted',
  CURBSIDE: 'curbside',
  DROP_OFF: 'dropoff',
  FRAME_SIZE: 'framesize',
  JEWELLERY_TYPE: 'jewellerytype',
  PHONE_CARRIER: 'phonecarrier',
  SCREEN_SIZE_LAPTOP: 'laptopscreensize',
  SHIPPING: 'shipping',
  SIZE: 'size',
}

const ATTRIBUTES_JOBS = {
  COMPANY: 'company',
  JOB_TYPE: 'jobtype',
  JOB_OFFERED_BY: 'jobofferedby',
  APPLICATION_LINK: 'applicationlink',
}

const ATTRIBUTES_VACATION_RENTALS = {
  AVAILABILITY_START_DATE: 'availabilitystartdate',
  AVAILABILITY_END_DATE: 'availabilityenddate',
}

export const ATTRIBUTES = {
  ...ATTRIBUTES_AUTOS,
  ...ATTRIBUTES_BUY_SELL,
  ...ATTRIBUTES_JOBS,
  ...ATTRIBUTES_PETS,
  ...ATTRIBUTES_RE,
  ...ATTRIBUTES_VACATION_RENTALS,
  PAYMENT: 'payment',
  FULFILLMENT: 'fulfillment',
  FOR_SALE_BY: 'forsaleby',
} as const

/**
 * This is a list of all attributes that should be rendered as a link
 */
export const ATTRIBUTES_URL_VALUE = [ATTRIBUTES.APPLICATION_LINK]

/** ATTRIBUTES VALUES */
export enum VEHICLE_TYPE_VALUES {
  NEW = 'new',
  USED = 'used',
  LEASE = 'lease',
  DAMAGED = 'damaged',
  SALVAGE = 'salvage',
}

export enum UNIT_TYPE_VALUES {
  APARTMENT = 'apartment',
  BASEMENT_APARTMENT = 'basement-apartment',
  CONDO = 'condo',
  DUPLEX = 'duplex-triplex',
  HOUSE = 'house',
  TOWNHOUSE = 'townhouse',
}

export enum FOR_SALE_BY_VALUES {
  DEALER = 'delr',
  OWNER = 'ownr',
}
export enum PET_FRIENDLY_VALUES {
  LIMITED = 'limited',
  FALSE = '0',
  TRUE = '1',
}
export enum PET_GENDER_VALUES {
  FEMALE = 'female',
  MALE = 'male',
  NOT_SPECIFIED = 'notspecified',
}

export enum BOOLEAN_NUMERIC_VALUES {
  YES = '1',
  NO = '0',
}

export enum BOOLEAN_TEXT_VALUES {
  YES = 'true',
  NO = 'false',
}

export enum CAR_TRANSMISSION_VALUES {
  MANUAL = '1',
  AUTOMATIC = '2',
  OTHER = '3',
  SEMI_AUTOMATIC = '0',
}
export enum CAR_FUEL_VALUES {
  DIESEL = 'diesel',
  ELECTRIC = 'electric',
  GAS = 'gas',
  HYBRID = 'hybrid',
  OTHER = 'other',
}

export enum CAR_BODY_TYPE_VALUES {
  CONVERTIBLE = 'conv',
  COUPE = 'coup',
  HATCHBACK = 'htchbck',
  PICKUP_TRUCK = 'pickuptruck',
  SEDAN = 'sedan',
  SUV_CROSSOVER = 'suvcrossover',
  VAN = 'vanminicomma',
  WAGON = 'wagon',
  OTHER = 'othrbdytyp',
}

export enum FULFILLMENT_VALUES {
  DELIVERY = 'delivery',
  SHIPPING = 'shipping',
  CURBSIDE = 'curbside',
}

export enum PAYMENT_VALUES {
  CASHLESS = 'cashless',
  CASH_ACCEPTED = 'cashaccepted',
}

export enum RENTALS_VIRTUAL_OPTIONS_VALUES {
  ONLINE_APPLICATION = 'onlineapplication',
  VIDEO_CHAT = 'videochat',
  VIDEO_TOUR = 'virtualtour',
}

export enum TERM_AGREEMENT_VALUES {
  RENT_DEFERRAL = 'rentdeferral',
}

export enum FOR_RENT_BY_HOUSING_VALUES {
  OWNER = 'ownr',
  PROFESSIONAL = 'reprofessional',
}
