import { type NextRouter } from 'next/router'

/**
 * Redirects the user to the Post Ad Form (PAF) to the desired category
 * This function constructs a URL to the PAF page on the current domain and appends a
 * predefined query parameter for a category
 *
 * @example
 * // Assuming the current domain is "https://www.example.com"
 * navigateToPAF();
 * // This would redirect the user to:
 * // "https://www.example.com/p-post-ad.html?categoryId=174"
 *
 */
export const navigateToPAF = (push: NextRouter['push'], categoryId?: number): void => {
  if (!categoryId) {
    push('/p-post-ad.html')
    return
  }

  push(`/p-post-ad.html?categoryId=${categoryId}`)
}
