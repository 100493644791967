import styled from 'styled-components'

import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

/**
 * @description Styles for the Max Length Indicator
 */
export const MaxLengthIndicatorText = styled.span(
  ({ theme }) => `
    ${styleFromTypography(theme.typography.body.xSmall)}
    color: ${theme.colors.grey.light2};
    position: absolute;
    top: ${theme.spacing.defaultSmall};
    right: ${theme.spacing.default};
    z-index: 1;
  `
)

/**
 * @description Container for the Max Length Indicator
 */
export const MaxLengthIndicatorContainer = styled.div`
  position: relative;
`
