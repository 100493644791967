import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCheckmarkAlt: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M13.518 5.738a1 1 0 10-1.35-1.476L6.281 9.645 3.675 7.262a1 1 0 00-1.35 1.476l3.281 3a.997.997 0 001.35 0z"
        fill="#696696"
        fillRule="evenodd"
      />
    </Icon>
  )
}
export default SvgCheckmarkAlt
