// To ensure accessibility standards are met we must associate all relevant input field information with the field HTML element. We've chosen to associate error messages and help text with their associated field using the aria-describedby attribute. aria-describedby accepts multiple ids separated by a space. The getAriaDescribedByValue function processes various field data ids and returns a valid aria-describedby value.

export const getAriaDescribedByValue = (
  errorId: string | undefined,
  helperText: string | undefined,
  id: string
): string => {
  const idArray: string[] = []

  if (errorId) {
    idArray.push(errorId)
  }

  if (helperText) {
    idArray.push(`${id}-helper-text`)
  }

  return idArray.reduce(
    (ariaDescribedByValue: string, currentId: string): string =>
      ariaDescribedByValue ? `${ariaDescribedByValue} ${currentId}` : currentId,
    ''
  )
}
